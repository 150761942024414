import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { InvoiceData } from "../../../../redux/types";
import { updateFactStatutAsync } from "../../../../utils/api/invoice.api";
import { cleannerError, onHide } from "../../../../utils/utils";

function UseCrudFacture(
  facture: InvoiceData,
  func: any,
  invoiceId: string,
  modalId: string,
  btnId: string
) {
  const validationSchema = yup.object().shape({
    type_depense: yup.string().required().label("Le type de depense"),
    categorie: yup.string().notRequired().label("La catégorie"),
    montant_ht: yup.number().required().label("Le montant HT"),
    montant_ttc: yup.number().required().label("Le montant TTC"),
    tva: yup.number().required().label("Le tva"),
    commerce_name: yup.string().notRequired().label("Le nom"),
    date: yup.string().required().label("La date"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<Partial<InvoiceData>>({
    resolver: yupResolver(validationSchema),
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const handleReset = () => {
    // reset();
  };

  const onSubmit = async (data: Partial<InvoiceData>) => {
    setIsLoading(true);
    try {
      
      data.date = moment(data.date, "YYYY-MM-DD").format("DD/MM/YYYY");
      
      await updateFactStatutAsync(invoiceId, data);

      await Swal.fire({
        icon: "success",
        title: "Facture modifiée avec succès!",
        showConfirmButton: false,
        iconColor: "#02B4C0",
        timer: 2000,
      }).then(async () => {
        await func();
        await onHide(modalId, btnId);
        setIsLoading(false);
        handleReset();
      });
    } catch (ex) {
      //   setError("email", { message: "Identifiants incorrects" });
      
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Une erreur est survenue!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
  };
}

export default UseCrudFacture;
