import { deleteDoc, doc } from "firebase/firestore";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AiFillEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { db } from "../../../../firebase";
import {
  getDateFromString,
  getFactureDate,
} from "../../../../redux/slice/invoiceSlice";
import { useAppSelector } from "../../../../redux/store";
import { InvoiceData } from "../../../../redux/types";
import { formatAmout } from "../../../../utils/formatter";
import FacturesTableSkeleton from "./FacturesTableSkeleton";

function FacturesTable({
  data = [],
  isLoading,
  func,
}: {
  data: InvoiceData[];
  isLoading: boolean;
  func?: any;
}) {
  const { userInfos } = useAppSelector((s) => s?.auth);

  const onDeleteInvoices = async (uid) => {
    const docRef = doc(db, "invoices", uid);
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette facture ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: "#02B4C0",
      confirmButtonColor: "#02B4C0",
      preConfirm: () => {
        return deleteDoc(docRef);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result: any) => {
      if (result?.value) {
        if (func) {
          await func();
        }

        await Swal.fire({
          icon: "success",
          title: `Facture supprimée avec succès!`,
          iconColor: "#02B4C0",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const actionFormatter: any = (cell, row) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={
                userInfos?.is_admin
                  ? `/admin/facture/${cell}`
                  : `/super-admin/facture/${cell}`
              }
              state={row}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
              onClick={() => onDeleteInvoices(cell)}
            >
              <BsTrashFill />
            </button>
          </div>
        </div>
      </>
    );
  };

  const options = {
    pageStartIndex: 1,
    sizePerPage: 10,
    hidePageListOnlyOnePage: true,
  };

  const columns = [
    {
      dataField: "userId.nom",
      text: "Société/Nom",
      filter: textFilter({ placeholder: "Chercher..." }),
      headerStyle: () => {
        return { width: "200px" };
      },
    },
    {
      dataField: "userId.prenom",
      text: "Agence/Prénom",
      filter: textFilter({ placeholder: "Chercher..." }),
      headerStyle: () => {
        return { width: "200px" };
      },
    },
    {
      dataField: "number",
      text: "N°",
      filter: textFilter({ placeholder: "Chercher..." }),
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    {
      dataField: "date",
      formatter: (cell, row) =>
        getDateFromString(row.date, row.created_at).format("DD/MM/YYYY"),
      text: "Date",
      filter: textFilter({ placeholder: "Chercher..." }),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "desc") {
          return getFactureDate(rowB) - getFactureDate(rowA);
        }
        return getFactureDate(rowA) - getFactureDate(rowB);
      },
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    {
      dataField: "montant_ttc",
      text: "Montant",
      filter: textFilter({ placeholder: "Chercher..." }),
      formatter: formatAmout,
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    {
      dataField: "uid",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "130px" };
      },
    },
  ];

  return (
    <>
      {isLoading && <FacturesTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="uid"
            data={data}
            columns={columns}
            bordered={true}
            condensed={false}
            sort={{ dataField: "date", order: "desc" }}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée disponible"}
            pagination={paginationFactory(options)}
            filter={filterFactory()}
          />
        </>
      )}
    </>
  );
}

export default FacturesTable;
