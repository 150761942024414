import { useEffect } from "react";
import { getDateFromString } from "../../../redux/slice/invoiceSlice";
import { InvoiceData } from "../../../redux/types";
import { getNameLabel, useUserCategories } from "../../../utils/utils";
import ErrorMessage from "../../common/ErrorMessage";
import CloseModalBtn from "../../shared/CloseModalBtn";
import UseCrudFacture from "./request/UseCrudFacture";
const EditFactureModal = ({
  facture,
  func,
  invoiceId,
  modalId,
  btnId,
}: {
  facture: InvoiceData;
  func: any;
  invoiceId: string;
  modalId: string;
  btnId: string;
}) => {
  const categories = useUserCategories(facture.userId);
  const { register, errors, onSubmit, isLoading, setValue, handleReset } =
    UseCrudFacture(facture, func, invoiceId, modalId, btnId);

  useEffect(() => {
    if (facture) {
      setValue("commerce_name", facture?.commerce_name);
      setValue("montant_ht", facture?.montant_ht);
      setValue("montant_ttc", facture?.montant_ttc);
      setValue("tva", facture?.tva);
      setValue(
        "date",
        getDateFromString(facture?.date, facture?.created_at).format(
          "YYYY-MM-DD"
        )
      );
      setValue("type_depense", facture?.type_depense);
      setValue("nom_client", facture?.nom_client);
      setValue("categorie", facture?.categorie);
    }
    return () => {};
  }, [facture]);

  return (
    <div className="modal-dialog modal-md modal-dialog-centered">
      <div className="modal-content py-2">
        <div className="modal-header d-flex justify-content-between align-items-center">
          <h1 className="admin-modal-title text-start">Modifier une facture</h1>
          <CloseModalBtn />
        </div>
        <div className="container-form px-3">
          <form onSubmit={onSubmit}>
            <div className="row pt-4">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="commerce_name"
                    className="form-label form-label-modal-custom"
                  >
                    {getNameLabel(facture?.type_depense)}
                  </label>
                  <input
                    type="text"
                    placeholder={getNameLabel(facture?.type_depense)}
                    className="form-control form-control-modal-custom"
                    id="commerce_name"
                    {...register("commerce_name")}
                  />
                  <ErrorMessage message={errors?.commerce_name?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="montant_ht"
                    className="form-label form-label-modal-custom"
                  >
                    Montant HT<span className="badge-danger">*</span>
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    min={0}
                    placeholder="Montant"
                    className="form-control form-control-modal-custom"
                    id="montant_ht"
                    {...register("montant_ht")}
                  />
                  <ErrorMessage message={errors?.montant_ht?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="tva"
                    className="form-label form-label-modal-custom"
                  >
                    TVA <span className="badge-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="TVA"
                    className="form-control form-control-modal-custom"
                    id="tva"
                    {...register("tva")}
                  />
                  <ErrorMessage message={errors?.tva?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="tva"
                    className="form-label form-label-modal-custom"
                  >
                    Montant TTC <span className="badge-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Montant TTC"
                    className="form-control form-control-modal-custom"
                    id="tva"
                    {...register("montant_ttc")}
                  />
                  <ErrorMessage message={errors?.montant_ttc?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="date"
                    className="form-label form-label-modal-custom"
                  >
                    Date
                  </label>
                  <input
                    type="date"
                    placeholder="Date"
                    className="form-control form-control-modal-custom"
                    id="date"
                    {...register("date")}
                  />
                  <ErrorMessage message={errors?.date?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="categorie"
                    className="form-label form-label-modal-custom"
                  >
                    Catégorie
                  </label>
                  <select
                    className="form-control form-control-modal-custom"
                    id="categorie"
                    {...register("categorie")}
                  >
                    <option value="" selected disabled>
                      Choisir une catégorie
                    </option>
                    {categories.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage message={errors?.categorie?.message} />
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label
                    htmlFor="type_depense"
                    className="form-label form-label-modal-custom"
                  >
                    Type de dépense
                  </label>
                  <select
                    className="form-control form-control-modal-custom"
                    id="type_depense"
                    {...register("type_depense")}
                  >
                    <option value="" selected disabled>
                      Choisir un type de dépense
                    </option>
                    <option value="Dépense">Dépense</option>
                    <option value="Émise">Émise</option>
                  </select>
                  <ErrorMessage message={errors?.type_depense?.message} />
                </div>
              </div>
            </div>
            <div className="container-btn-modal row mt-4">
              <div className="col-md-6 offset-md-6">
                <button
                  className="btn auth-submit-btn"
                  type="submit"
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    "Modifier"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Enregistrement...</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditFactureModal;
