import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { Categorie } from "../../../../redux/types";

type GraphData = { cat: Categorie; count: number };

const DonutChart = ({ data }: { data: GraphData[] }) => {
  const options: ApexOptions = {
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1024,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels: data.map((i) => i.cat.name),
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
            },
          },
        },
        expandOnClick: true,
      },
    },
    colors: data.map((c) => () => c.cat.color),
  };
  const series = data.map((c) => c.count);

  return (
    <div className="donut">
      <Chart
        options={options}
        series={series}
        type="donut"
        width={800}
        height={400}
      />
    </div>
  );
};

export default DonutChart;
