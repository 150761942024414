import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AiFillEye } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
function ListeUsersComtabiliteTable() {
  const users = useAppSelector((s) => s.users2);

  const actionFormatter = (cell, row) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/comptabilite/utilisateur/${row.uid}`}
              state={row}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div>
        </div>
      </>
    );
  };

  const options = {
    pageStartIndex: 1,
    sizePerPage: 10,
    hidePageListOnlyOnePage: true,
  };

  const columns = [
    {
      dataField: "id",
      text: "N°",
    },
    {
      dataField: "nom",
      text: "Société/Nom",
      filter: textFilter({ placeholder: "Chercher..." }),
      sort: true,
    },
    {
      dataField: "prenom",
      text: "Agence/Prénom",
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "email",
      text: "Mail Société/Agence",
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "invoiceCount",
      text: "Factures",
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <BootstrapTable
      keyField="id"
      data={users}
      sort={{ dataField: "nom", order: "asc" }}
      columns={columns}
      bordered={true}
      condensed={false}
      responsive={true}
      wrapperClasses="table-responsive admin-table"
      noDataIndication={() => "Il ya pas de données"}
      rowStyle={{ cursor: "pointer" }}
      pagination={paginationFactory(options)}
      filter={filterFactory()}
    />
  );
}

export default ListeUsersComtabiliteTable;
