import { deleteDoc, doc } from "firebase/firestore";
import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AiFillEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { RiEdit2Fill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { db } from "../../../../firebase";
import { useAppSelector } from "../../../../redux/store";
import { UserInfos } from "../../../../redux/types";
import AddAdminModal from "../../../modal/admin/AddAdminModal";
import ListeAdminsTableSkeleton from "./ListeAdminsTableSkeleton";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";


function ListeAdminsTable({ word }: { word?: string }) {
  const { userInfos } = useAppSelector((s) => s?.auth);
  const [isLoading, setIsLoading] = React.useState(false);
  const users = useAppSelector((s) => s.users);
  const [data, setData] = React.useState<UserInfos[]>([]);
  useEffect(() => {
    setData([...users].filter((f) => f.is_admin));
  }, [users]);

  const onDeleteAdmin = async (uid) => {
    const docRef = doc(db, "users", uid);
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet administateur ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: "#02B4C0",
      confirmButtonColor: "#02B4C0",
      preConfirm: () => {
        return deleteDoc(docRef);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Administateur supprimé avec succès!`,
          iconColor: "#02B4C0",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              //   to={`/admin/utilisateur/${row.uid}`}
              to={
                userInfos?.is_admin
                  ? `/admin/administrateur/${cell}`
                  : `/super-admin/administrateur/${cell}`
              }
              state={row}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target={`#editAdminModal${row.uid}`}
              id={`btnAdminEditAdlin${row.uid}`}
            >
              <RiEdit2Fill />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
              onClick={() => onDeleteAdmin(row.uid)}
            >
              <BsTrashFill />
            </button>
          </div>
        </div>
        <div
          className="modal fade"
          id={`editAdminModal${row.uid}`}
          aria-labelledby="editAdminModalLabel"
          aria-hidden="true"
        >
          <AddAdminModal
            modalId={`editAdminModal${row.uid}`}
            admin={row}
            func={() => null}
            btnId={`btnAdminEditAdlin${row.uid}`}
          />
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "prenom",
      text: "Prénom",
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "nom",
      text: "Nom",
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "email",
      text: "Email",
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "uid",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  const options = {
    pageStartIndex: 1,
    sizePerPage: 10,
    hidePageListOnlyOnePage: true,
  };
  return (
    <>
      {isLoading && <ListeAdminsTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="uid"
            data={data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée disponible"}
            pagination={paginationFactory()}
            filter={filterFactory(options)}
          />
        </>
      )}
    </>
  );
}

export default ListeAdminsTable;
